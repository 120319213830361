/**
 * This file contains authentication parameters. Contents of this file
 * is roughly the same across other MSAL.js libraries. These parameters
 * are used to initialize Angular and MSAL Angular configurations in
 * in app.module.ts file.
 */

import { LogLevel, Configuration, BrowserCacheLocation, InteractionType } from '@azure/msal-browser';
const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

/**
 * Configuration object to be passed to MSAL instance on creation. 
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md 
 */
export const msalConfig: Configuration = {
  // MSAL Configuration
  auth: {
    clientId: '3fff24ee-ce49-4a8c-875a-703499a13add', // This is the ONLY mandatory field that you need to supply.
    authority: 'https://login.microsoftonline.com/f90bd66c-40de-4bf9-9e0a-56de7ca5211e', // Tenant
    redirectUri: '/', // Points to window.location.origin. You must register this URI on Azure portal/App Registration.
    //redirectUri: 'http://localhost:4200',
    postLogoutRedirectUri: '/',
    //postLogoutRedirectUri: 'http://localhost:4200'
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage, // Configures cache location. "SessionStorage" is more secure, but "LocalStorage" gives you SSO between tabs.
    storeAuthStateInCookie: isIE, // Set this to "true" if you are having issues on IE11 or Edge
    //secureCookies: false
  },

  system: {
    allowRedirectInIframe: true,
    loggerOptions: {
      loggerCallback: (level: LogLevel, message: string, containsPii: boolean): void => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
        }
      },
      logLevel: LogLevel.Verbose,
      piiLoggingEnabled: false
    },
    /*
    The default timeout is about 10 seconds and should be sufficient in most cases, however, if your identity provider is taking longer than that to redirect 
    you can increase this timeout in the MSAL config with either the iframeHashTimeout, windowHashTimeout or loadFrameTimeout configuration parameters.
    */
    //windowHashTimeout: 60000, // Applies just to popup calls - In milliseconds (9000) - 60000
    //iframeHashTimeout: 6000, // Applies just to silent calls - In milliseconds (9000) - 6000
    //loadFrameTimeout: 0, // Applies to both silent and popup calls - In milliseconds (9000) - 0
    //asyncPopups: false
  }
}

/**
 * Add here the endpoints and scopes when obtaining an access token for protected web APIs. For more information, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */
export const protectedResources = {
  graphApiUser: {
    endpoint: "https://graph.microsoft-ppe.com/v1.0/me",// PPE testing environment
    scopes: ["User.Read.All"]//,"Directory.Read.All"]
  },
  graphApiGroups: {
    endpoint: "https://graph.microsoft.com/v1.0/me/checkMemberGroups",
    scopes: ["Group.Read.All", "GroupMember.Read.All"]
  }
}

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit: 
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = {
  scopes: ['user.read', 'directory.read.all']
};

export const groups = {
  groupIT: "e6729c8a-65e3-4a5c-a86b-9f61b9faf7c0",
  groupManagement: "7f3a98d6-2313-4687-ab42-59546c24342a",
  groupMk3Staff: "6f104fe7-7f41-4b9f-b689-c3057c8ec1ab",
  groupAdmin: "6b86718d-92ea-46a5-956d-73e14005366c",//objectID for GroupAdmin group from Azure Portal
  groupPrivileged: "05864855-23ea-4c45-8759-5254a67acd65",//objectID for GroupPrivileged group from Azure Portal
  groupMember: "22527e48-0994-4484-86df-3680f1736d14",//objectID for GroupMember group from Azure Portal
  groupHMA: "8a027ff8-7d2c-4a79-a40d-b297d00a2fe8",//HMA, not AssessorsHMA or SA
  Mk3Contractors: "9c9d08d1-00f9-4ed3-8cd8-3bf268bbba0f",
  groupAssessors: "f9c0bf88-ab91-472f-9deb-01aa232d7b16",//All Assessors
  groupEstimators:"c0cd7d4a-cdb3-409a-89f5-c01074cae877",
  groupStateAdminManagers:"5d4bb495-a5b5-4f20-92af-d2adaee694e4",//Kelly, Kim (Not State Managers)
}