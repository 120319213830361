export class UserActionsLogDatum {
    actionID: number | null = 0;
    refNo: number = 0;
    employeeID: number = 0;
    fieldName: string = '';
    oldValue: string | null = null;
    newValue: string | null = null;
    timestamp: Date | null = null;
    systemTimestamp: Date | null = null;
    isClaimClosureAction:boolean = false;
    newID: number | null = null;
    autoUpdateFromField: string | null = null;

    constructor(x: { refNo: number; employeeID: number;})//fieldName: string;oldValue: string | null;newValue: string | null}) 
    { this.refNo = x.refNo; this.employeeID=x.employeeID; }//this.fieldName=x.fieldName;this.oldValue=x.oldValue;this.newValue=x.newValue;}
}