import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule, DatePipe } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatIconModule } from '@angular/material/icon';
import { MatSortModule } from '@angular/material/sort';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CaseDetailComponent } from './_components/case-detail/case-detail.component';
import { CaseComponent } from './_components/case/case.component';
import { Mk3MaterialModule } from './_shared/material-module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AlertComponent } from './_components';
import { HomeComponent } from './_components/home';
import { ReportsComponent } from './_components/reports/reports.component';
import { E2eEolComponent } from './_components/reports/e2e-eol/e2e-eol.component';
import { CatClaimsComponent } from './_components/reports/cat-claims/cat-claims.component';
import { ClaimReceivedSummaryComponent } from './_components/reports/claim-received-summary/claim-received-summary.component';
import { DateAdapter, MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { AllOpenClaimsComponent } from './_components/reports/all-open-claims/all-open-claims.component';
import { OpenClaimsListComponent } from './_components/reports/open-claims-list/open-claims-list.component';
import { MatTableExporterModule } from 'mat-table-exporter';
import { AutoFocusDirective } from './_directives/auto-focus.directive';
// import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { BrowserCacheLocation, InteractionType, IPublicClientApplication, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { MsalBroadcastService, MsalGuard, MsalGuardConfiguration, MsalInterceptor, MsalInterceptorConfiguration, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalModule } from '@azure/msal-angular';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NewNoteComponent } from './_components/case-detail/new-note/new-note.component';
import { InputRefNoDialog } from './_components/home/home.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ContactDetailComponent } from './_components/case-detail/contact-detail/contact-detail.component';
import { ManagementDashComponent } from './_components/reports/management-dash/management-dash.component';
import "@angular/compiler"
import { PreliminaryInspectionComponent } from './_components/case-detail/preliminary-inspection/preliminary-inspection.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { TradeComponent } from './_components/case-detail/trade/trade.component';
import { MatCardModule } from '@angular/material/card';
import { UpdateClaimComponent } from './_components/update-claim/update-claim.component';

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1; // Remove this line to use Angular Universal

export function loggerCallback(logLevel: LogLevel, message: string) {
  //console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: '4aa39415-8074-4e72-879b-1993d40f9f5a',
      authority: 'https://login.microsoftonline.com/f90bd66c-40de-4bf9-9e0a-56de7ca5211e', // Tenant
      redirectUri: '/',
      postLogoutRedirectUri: '/'
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE,// set to true for IE 11
    },
    system: {
      //allowNativeBroker: false, // Disables native brokering support
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false
      }
    }
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']);
  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ['user.read', 'directory.read.all']
    },
    loginFailedRoute: '/login-failed'
  };
}

//The app module defines the root module of the application along with metadata about the module
//This is where the fake backend provider is added to the application,
//to switch to a real backend simply remove the fakeBackendProvider located below the comment // provider used to create fake backend
@NgModule({
  declarations: [
    AppComponent,
    CaseComponent,
    CaseDetailComponent,
    AlertComponent,
    HomeComponent,
    ReportsComponent,
    E2eEolComponent,
    CatClaimsComponent,
    ClaimReceivedSummaryComponent,
    AllOpenClaimsComponent,
    OpenClaimsListComponent,
    AutoFocusDirective,
    NewNoteComponent,
    InputRefNoDialog,
    ContactDetailComponent,
    PreliminaryInspectionComponent,
    TradeComponent,
    UpdateClaimComponent
  ],
  imports: [
    MatIconModule,
    MatCardModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    CdkTableModule,
    CommonModule,
    MatPaginatorModule,
    MatSortModule,
    Mk3MaterialModule,
    ReactiveFormsModule,
    MatTableExporterModule,
    // NgIdleKeepaliveModule.forRoot(),
    ModalModule.forRoot(),
    //MomentModule,
    FlexLayoutModule,
    MatDatepickerModule,
    MatNativeDateModule,
    NgSelectModule,
    MsalModule
  ],

  providers: [
    DatePipe,
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },

    { provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true },
    { provide: MSAL_INSTANCE, useFactory: MSALInstanceFactory },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    { provide: MatDialogRef, useValue: {} },
    { provide: MAT_DIALOG_DATA, useValue: {} },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    MatDatepickerModule,
    MatNativeDateModule
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
