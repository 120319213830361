import { Location } from '@angular/common';
import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService, AlertService } from '@app/index';
import { Case } from '@app/_models/case';
import { ClaimContact, vClaimContact } from '@app/_models/claimContact';//import { ClaimContact } from '@app/_models/claimContact';
import { RestService } from '@app/_services/rest.service';
import { FunctionsService } from '@app/_services/functions.service';
import { ClaimNote } from '@app/_models/noteDetails';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { tap } from 'rxjs/operators';
import { NoteDetailsDialogComponent } from './note-details-dialog/note-details-dialog.component';
import { ContactDetailComponent } from './contact-detail/contact-detail.component';
import { AppComponent } from '@app/app.component';
import { Employee } from '@app/_models/employee.model';
import Swal from 'sweetalert2';
import { groups } from '@app/_services/auth-config';
import { VClaimsAll } from '@app/_models/vClaimsAll';
import { ClaimContactRole } from '@app/_models/claimContactRole.model';
import { User } from '@app/_models/user';

@Component({
  selector: 'app-case-detail',
  templateUrl: './case-detail.component.html',
  styleUrls: ['./case-detail.component.scss'],
})
export class CaseDetailComponent implements OnInit {
  insuredRefName:string='';
  @Input() isContactDetailsVisible: boolean = false;
  @Input() isInsurerContactDetailsVisible = false;
  @Input() isPolicyDetailsVisible: boolean = true;
  @Input() isClaimDetailsVisible: boolean = true;
  @Input() isAssessmentDetailsVisible: boolean = false;
  @Input() isContactDatesVisible: boolean = false;
  @Input() isDescriptionDatesVisible: boolean = false;

  toggleSection(id:number) {
    if (id===1)
      this.isPolicyDetailsVisible = !this.isPolicyDetailsVisible;
    else if (id===2)
      this.isContactDetailsVisible = !this.isContactDetailsVisible;
    else if (id===3)
      this.isInsurerContactDetailsVisible = !this.isInsurerContactDetailsVisible;
    else if (id===4)
      this.isClaimDetailsVisible = !this.isClaimDetailsVisible;
    else if (id===5)
      this.isAssessmentDetailsVisible = !this.isAssessmentDetailsVisible;
    else if (id===6)
      this.isContactDatesVisible = !this.isContactDatesVisible;
    else
      this.isDescriptionDatesVisible = !this.isDescriptionDatesVisible;
  }
  
  displayedNotesColumns: string[] = [
    'date',
    'initiatedBy',
    'addedBy',
    'private',
    'notes',
    'followUpDate',
    'typeDescription',
    'subTypeDescription',
    'reasonDescription',
  ];
  dataSourceNotes!: MatTableDataSource<ClaimNote>;
  allNotes!: ClaimNote[];
  isLoading = true;
  case!: VClaimsAll;
  claimContact!: ClaimContact[];
  claimContactRoles: ClaimContactRole[] = [];
  noteDetails!: ClaimNote;
  activeNoteRow = null;
  isHMAOrMk3ContractorsUser = true;
  isAssessorOrEstimator = false;
  isEstimator = false;
  isAssessorOrSAMOrManagement = false;
  isAlreadySent = false;
  isHMAUser = true;
  userEmail: string = '';
  userDetail!: Employee;
  claimNoteFilterOption = [
    { id: 0, optionText: 'All Notes' },
    { id: 13, optionText: 'Trade Attendance' },
    { id: 14, optionText: 'Trade Instruction' },
    { id: 24, optionText: 'Emergency Repair' },
    { id: 25, optionText: 'Specialist Information' },
    { id: 30, optionText: 'Trade invoice/signed instruction' },
    { id: 33, optionText: 'Assessor Note' },
  ];

  @ViewChild(MatSort, { static: true }) matSort!: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild(MatTable)
  table!: MatTable<any>;
  contactForm: UntypedFormGroup;

  constructor(
    public appComponent: AppComponent,
    private restService: RestService,
    private route: ActivatedRoute,
    private _location: Location,
    private alertService: AlertService,
    public functionsService: FunctionsService,
    private accountService: AccountService,
    private router: Router,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: vClaimContact,
    private fb: UntypedFormBuilder
  ) {
    this.appComponent.getUserDetails().subscribe((employee) => {
      this.userDetail = employee;
    });

    this.isHMAOrMk3ContractorsUser =
      this.appComponent.userGroupsArray.includes(groups.groupHMA) ||
      this.appComponent.userGroupsArray.includes(groups.Mk3Contractors);

      this.isAssessorOrEstimator =
      this.appComponent.userGroupsArray.includes(groups.groupAssessors) || 
        this.appComponent.userGroupsArray.includes(groups.groupEstimators);

      this.isEstimator = this.appComponent.userGroupsArray.includes(groups.groupEstimators)|| 
        this.appComponent.userGroupsArray.includes(groups.groupIT);
        
    this.isAssessorOrSAMOrManagement =
      this.appComponent.userGroupsArray.includes(groups.groupAssessors) ||
      this.appComponent.userGroupsArray.includes(
        groups.groupStateAdminManagers
      ) ||
      this.appComponent.userGroupsArray.includes(groups.groupManagement) ||
      this.appComponent.userGroupsArray.includes(groups.groupIT);

    this.alertService.clear();
    this.contactForm = this.fb.group({
      contactID: [data.contactID],
      refNo: [data.refNo],
      name: [data.name],
      phone: [data.phone],
      emailAddress: [data.emailAddress],
      note: [data.note],
      primaryContact: [data.primaryContact],
    });
  }

  async ngOnInit() {
    this.isHMAUser = this.appComponent.userGroupsArray.includes(groups.groupHMA);
    this.alertService.clear();
    await this.fetchData(false);
    this.fetchRoles();
    this.restService
      .getPreLiminaryInspectionDetails(this.route.snapshot.params.refNo)
      .subscribe((x) => {
        if (x) this.isAlreadySent = x.isSent;
      });
  }
  
  async fetchData(isAddNote: boolean) {
    await this.restService
      .getClaimByMk3Ref(this.route.snapshot.params['refNo'],this.isHMAUser,this.accountService.clientFriendlyName)
      .then(async (data) => {
        if (data && data.totalCount > 0) {
          this.case = { ...data.records[0] };
          this.isLoading = false;
          this.insuredRefName = this.case.brandID? (await this.restService.getBrandById(this.case.brandID)).insuredRefName:'';
      //.then((data: any) => {
        if (data?.records != null) {
          this.case = { ...data.records[0] };
          if (isAddNote)
            this.router.navigate([
              '../../case-detail/new-note/',
              this.case.refNo,
              this.case.caseNo,
              this.case.lastName,
              this.case.firstContactDate != null
                ? this.case.firstContactDate
                : 'null',
              this.case.lastContactDate != null
                ? this.case.lastContactDate
                : 'null',
            ]);
        }
        this.isLoading = false;

        //Get Notes
        this.restService
          .getClaimNotes(this.route.snapshot.params.refNo,this.isHMAOrMk3ContractorsUser)
          .subscribe(
            (notes) => {
              this.allNotes = notes;
              if (this.isHMAOrMk3ContractorsUser){
                this.dataSourceNotes = new MatTableDataSource(notes.filter(x=>x.isExternalAssessorVisible));
              }
              else {
                this.dataSourceNotes = new MatTableDataSource(notes);
              }
              this.dataSourceNotes.sort = this.matSort;
            },
            (error) => {
              this.isLoading = false;
              this.alertService.error(error);
            }
          );

        //Get Claim Contacts
       this.loadClaimContacts();
        } else {
          this.isLoading = false;
          this.router.navigate(['.']);
          this.alertService.error(
            'Invalid Claim. This claim either could be archived or not yet registered. Please contact Mk3 IT-Helpdesk'
          );
          return;
        }
      });
  }
  loadClaimContacts(){
    this.restService
    .getClaimContacts(this.route.snapshot.params.refNo)
    .subscribe(
      (contacts) => {
        this.claimContact = contacts;
        if (contacts.length == 0) {
          Swal.fire({
            title: 'No Contacts Found',
            text: 'Please inform lodgement team to add contact details for this claim.',
            icon: 'warning',
            confirmButtonColor: '#3085d6',
            confirmButtonText: `Yes`,
          });
        }
      },
      (error) => {
        this.isLoading = false;
        this.alertService.error(error);
      }
    );
  }

  addPreliminaryInspection() {
    this.FetchInspectionDetails();
  }

  AddNewNote() {
    this.fetchData(true);
  }

  addContactDetailDialog(): void {
    const dialogRef = this.dialog.open(ContactDetailComponent, {
      minWidth: 'auto',
      data: {
        claimContact: this.claimContact,
        isAddNewContact: true,
        refNo: this.route.snapshot.params.refNo,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.loadClaimContacts();
      console.log('The dialog was closed');
      if (result) {
        // Do something with the returned data
      }
    });
  }

  editContactDetailDialog(): void {
    const dialogRef = this.dialog.open(ContactDetailComponent, {
      minWidth: 'auto',
      data: {
        claimContact: this.claimContact,
        userDetail: this.userDetail,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.loadClaimContacts();
      console.log('The dialog was closed');
      if (result) {
        // Do something with the returned data
      }
    });
  }

  async FetchInspectionDetails() {
    this.isLoading = true;
    await this.restService
      .getClaimByMk3Ref(this.route.snapshot.params['refNo'],this.isHMAUser,this.accountService.clientFriendlyName)
      .then((data: any) => {
        if (data?.records != null) {
          this.case = { ...data.records[0] };
          this.router.navigate([
            '../../preliminary-inspection/',
            this.case.refNo,
            this.case.caseNo,
            this.case.lastName,
            this.isAlreadySent ?? false,
          ]);
        }
      });
  }

  openNoteDetailsDialog(row: null, note: string): void {
    this.activeNoteRow = row;
    const dialogRef = this.dialog
      .open(NoteDetailsDialogComponent, {
        minWidth: '400px',
        maxWidth: '1000px',
        data: { name: note },
      })
      .afterClosed()
      .pipe(tap(() => (this.activeNoteRow = null)));
    this.table.renderRows();
  }

  //Go back to the claims list page
  goBack() {
    this._location.back();
  }

  viewTrade() {
    this.isLoading = true;
    this.router.navigate(['../../trade/', this.case.refNo]);
  }

  updateClaim() {
    this.isLoading = false;
    this.router.navigate(['../../update-claim/', this.case.refNo]);
  }

  FilterNotes(event: any) {
    let result = this.allNotes;
    if (event.id) result = this.allNotes.filter((x) => x.reasonId == event.id);
    this.dataSourceNotes = new MatTableDataSource(result);
    this.dataSourceNotes.sort = this.matSort;
  }

  getClaimStatus(statusClaimTypeID: Number) {
    if (statusClaimTypeID == 1 || statusClaimTypeID == 3) {
      return 'Open';
    } else {
      return 'Finalised';
    }
  }

     
  fetchRoles() {
    this.restService.getClaimContactsRole().subscribe(
      roles => {
        this.claimContactRoles = roles;
      },
      error => {
        console.error('Fetch roles failed', error);
      }
    );
  }

  getRoleNameById(id: number): string {
    const role = this.claimContactRoles.find(role => role.contactTypeID === id);
    return role ? role.contactPerson : 'Unknown';
  }
}
