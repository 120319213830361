<section fxLayout="row wrap" fxLayoutAlign="center center" class="bdrop">
    <mat-card fxFlex="800px" fxFlex.xs="100%">
      <mat-card-title style="color: white; background-color: var(--mk3red); text-align: center;">Update Claim Details of RefNo 
        {{this.refNo}} {{this.lastNameS}}</mat-card-title>
      <form [formGroup]="form" autocomplete="off" novalidate (ngSubmit)="updateClaim(form.value)" fxLayout="column wrap"
        fxLayoutAlign="center center" fxLayoutGap="10px">
        <mat-card-content>

          <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px" style="width: 100%; margin-top: 20px;">
            <div fxFlex="50%">
              <mat-label style="font-size: large; font-weight: bold; margin-bottom: 10px;">Quote Submitted Date</mat-label>
            </div>
            <div fxFlex="50%">
              <mat-form-field appearance="outline" fxFlex="100%" id="customFormField">
                <input matInput [matDatepicker]="picker" style="font-size: medium;margin-bottom: 3px;" id="customDatepicker" placeholder="Choose a date" formControlName="quoteSubmittedDate" id="quoteSubmittedDate" (click)="picker.open()" [max]="today">
                <mat-datepicker-toggle matSuffix [for]="picker" style='height: 5em'></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
                <mat-error *ngIf="hasError('quoteSubmittedDate', 'required')">Valid date is required</mat-error>
              </mat-form-field>
            </div>
          </div>

          <div fxLayout="column" fxLayoutGap="20px" style="width: 100%; margin-top: 20px;">
            <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">
              <div fxFlex="50%">
                <label style="font-size: large; font-weight: bold;">Bld Estimate</label>
              </div>
              <div fxFlex="50%">
                  <input type="number" step="0.01" format="1.2-2" (change)="onChangeFees($event)" autofocus formControlName="bldEstimate" class="form-control" placeholder="$000.00" [ngClass]="{ 'is-invalid': form.controls.bldEstimate.errors }"/>
                  <mat-error *ngIf="hasError('bldEstimate', 'pattern')" style="display: inline-block;">Invalid!</mat-error>
              </div>
            </div>
          </div>

          <div fxLayout="column" fxLayoutGap="20px" style="width: 100%; margin-top: 20px;">
            <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px" >
              <div fxFlex="50%">
                <label style="font-size: large; font-weight: bold;">Restoration Estimate</label>
              </div>
              <div fxFlex="50%">
                  <input type="number" step="0.01" format="1.2-2" (change)="onChangeFees($event)" autofocus formControlName="restorationEstimate" class="form-control" placeholder="$000.00" [ngClass]="{ 'is-invalid': form.controls.restorationEstimate.errors }"/>
                  <mat-error *ngIf="hasError('restorationEstimate', 'pattern')" style="display: inline-block;">Invalid!</mat-error>
              </div>
            </div>
          </div>

          <!-- <div fxLayout="column" fxLayoutGap="20px" style="width: 100%; margin-top: 20px;">
            <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px" >
              <div fxFlex="50%">
                <label style="font-size: large; font-weight: bold;">Claim Original Status</label>
              </div>
              <div fxFlex="50%">
                <select placeholder="Select Original Status Type" formControlName="originalStatusId" class="form-control" style="display: flex;">
                  <option *ngFor="let item of originalStatus" [ngValue]="item.originalStatusId">
                    {{ item.originalStatus }}
                  </option>
                </select>
              </div>
            </div>
          </div> -->

        </mat-card-content>
        <mat-card-actions>
          <button mat-raised-button class="btn btn-danger" style="margin-right: 20px ;" [disabled]="!form.valid">Update Claim</button>
          <button type="button" mat-raised-button class="btn btn-danger" (click)="onCancel()">Cancel</button>
        </mat-card-actions>
      </form>
    </mat-card>
  </section>