import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { RestService } from '@app/_services/rest.service';
import { from, Observable, throwError } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe, Location } from '@angular/common';
import {AccountService, AlertService } from '@app/index';
import { MsalService } from '@azure/msal-angular';
import { catchError, concatMap, first } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { groups } from '@app/_services/auth-config';
import { AppComponent } from '@app/app.component';
import { ClaimDetails } from '@app/_models/claimDetails';
import { OriginalStatusTypes } from '@app/_models/originalStatusTypes';
import { UserActionsLogDatum } from '@app/_models/userActionsLogDatum';
import { Employee } from '@app/_models/employee.model';
import { Note } from '@app/_models/note';


@Component({
  selector: 'app-update-claim',
  templateUrl: './update-claim.component.html',
  styleUrls: ['./update-claim.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UpdateClaimComponent implements OnInit {
  public form!: UntypedFormGroup;
  errorMsg!: string;
  refNo!: number;
  employeeId:number=0;
  isAssessorOrEstimator:boolean=false;
  claim!:ClaimDetails;
  //originalStatus!: OriginalStatusTypes[];//Observable<any>;
  lastNameS!: string;
  loading = false;
  submitted = false;
  result!: string;
  @Input() max: any;
  today:Date=new Date();
  newNote!:Note;
  //isHMAUser!:boolean; 
  //isHMAClaim!:boolean;

  constructor(
    private activatedRoute: ActivatedRoute,
    private accountService: AccountService,
    public appComponent: AppComponent,
    private location: Location,
    private restService: RestService,
    private alertService: AlertService,
    private msalService: MsalService,
    private datePipe: DatePipe,
    private router: Router) {
    this.activatedRoute.params.subscribe(params => {
      this.refNo = params['refNo'];
      this.lastNameS = params['lastNameS'];
    });
    this.form = new UntypedFormGroup({
      bldEstimate: new UntypedFormControl('', [ Validators.maxLength(10),
        Validators.pattern(
          /^(?![0,.]+$)(?:0|[1-9]\d{0,2}(?:,\d{3})*|[1-9]\d*)(?:\.\d{1,2})?$/
        ),]),
      restorationEstimate: new UntypedFormControl('', [ Validators.maxLength(10),
        Validators.pattern(
          /^(?![0,.]+$)(?:0|[1-9]\d{0,2}(?:,\d{3})*|[1-9]\d*)(?:\.\d{1,2})?$/
        ),]),
      //originalStatusId: new UntypedFormControl(''),
      quoteSubmittedDate: new UntypedFormControl(this.today, [Validators.required])
    });
  }

  ngOnInit(): void {
    this.alertService.clear();
    // this.restService.getOriginalStatusTypes().subscribe((status:OriginalStatusTypes[])=>{
    //   this.originalStatus = status.filter(x=>x.originalStatusId === 36 || x.originalStatusId ===37);
    // });
    this.getRemoteData();
    const email = this.msalService.instance.getActiveAccount()!.username!;
    this.restService.getUserDetailsByEmail(email).subscribe((x:Employee)=>{
      this.employeeId=x.employeeId; 
    });
    this.form.get('bldEstimate')?.valueChanges.subscribe(value => {
      if (value !== null && value !== undefined) {
        const formattedValue = parseFloat(value).toFixed(2);
        this.form.get('bldEstimate')?.setValue(formattedValue, { emitEvent: false });
      }
    });

    this.form.get('restorationEstimate')?.valueChanges.subscribe(value => {
      if (value !== null && value !== undefined) {
        const formattedValue = parseFloat(value).toFixed(2);
        this.form.get('restorationEstimate')?.setValue(formattedValue, { emitEvent: false });
      }
    });
  }

  onChangeFees(event: Event, index:number=-1){
    const inputElement = event.target as HTMLInputElement;
    if (inputElement && inputElement instanceof HTMLInputElement) {
      const feeControl = inputElement.value;
      const formControlName = inputElement.getAttribute('formControlName');
      const num = parseFloat(feeControl);
      if (!isNaN(num)) {
        if (feeControl && formControlName == 'bldEstimate'){
            this.form.controls.bldEstimate.setValue(num.toFixed(2));
        }
        else if (feeControl && formControlName == 'restorationEstimate'){
          this.form.controls.restorationEstimate.setValue(num.toFixed(2));
        }
      }
    }
  }

  getRemoteData() {
    try {
      this.loading = true;
      this.restService.getClaimDetailsForUpdate(this.refNo).subscribe((cases:ClaimDetails) => {
      if (cases){
        this.claim=cases;
        this.form = new UntypedFormGroup({
          bldEstimate: new UntypedFormControl(cases.bldEstimate === null || cases.bldEstimate === undefined ? '' : cases.bldEstimate.toFixed(2)),
          restorationEstimate: new UntypedFormControl(cases.restorationEstimate !== null && cases.restorationEstimate !== undefined? cases.restorationEstimate.toFixed(2):''),
          //originalStatusId: new UntypedFormControl(cases.originalStatusId),
          quoteSubmittedDate: new UntypedFormControl(cases.quoteSubmittedDate ? cases.quoteSubmittedDate : this.today)
        });
        this.loading = false;
      }
      },
      error => {
        this.loading = false;
        return false;
      }
      );
      return true;
    }
    catch (error: any) {
      this.alertService.error(error);
      return false;
    }
  }

  public onCancel = () => {
    this.location.back();
  }

public updateClaim= (formValue: any) => {
  if (this.form.valid) {

    Swal.fire({
      title: 'Are you sure you want to update the Claim details?',
      icon: 'question',
      showDenyButton: true,
      confirmButtonText: `Yes`,
      denyButtonText: `No`,
      confirmButtonColor: '#007bff',
      denyButtonColor: '#dc3545',
      focusDeny: true,
      width: 450,
      padding: '1em',
      heightAuto: false
    }).then((result) => {
      if (result.isConfirmed) {
        this.submitted = true;
        this.alertService.clear();
        if (!this.form.controls.quoteSubmittedDate.value){
          Swal.fire({
            title: '<strong>Quote Submitted Date is empty!</strong><br/> Please set a valid Quote Submitted Date before update!',
            icon: 'warning',
            showDenyButton: false,
            confirmButtonText: `OK`,
            confirmButtonColor: '#007bff',
            width: 450,
            padding: '1em',
            heightAuto: false,
          }).then((result) => {
            if (result.isConfirmed) {
              return;
            }
          });
        }
        else if (!this.form.controls.bldEstimate.value && !this.form.controls.restorationEstimate.value) {
          Swal.fire({
            title: '<strong>Estimations are empty!</strong><br/> Both Bld Estimate and Restoration Estimate were empty, please make sure enter at least one Estimation before update!',
            icon: 'warning',
            showDenyButton: false,
            confirmButtonText: `OK`,
            confirmButtonColor: '#007bff',
            width: 450,
            padding: '1em',
            heightAuto: false,
          }).then((result) => {
            if (result.isConfirmed) {
              return;
            }
          });
        }
        else{
          const body = [
            { "op": "replace", "path": "/bldEstimate", "value": Number(this.form.controls.bldEstimate.value) },
            { "op": "replace", "path": "/originalStatusId", "value": 37 },
            { "op": "replace", "path": "/quoteSubmittedDate", "value": this.datePipe.transform(this.form.controls.quoteSubmittedDate.value, 'yyyy-MM-dd')?.toString()! },//this.form.controls.quoteSubmittedDate.value },
            { "op": "replace", "path": "/refNo", "value": this.refNo },
            { "op": "replace", "path": "/restorationEstimate", "value": Number(this.form.controls.restorationEstimate.value) }
          ];
        
          const userFullName = this.msalService.instance.getActiveAccount()!.name!;
          this.newNote = {
            refNo: this.refNo,
            date: this.datePipe.transform(this.today, 'yyyy-MM-dd')?.toString()!,//this.today.toLocaleDateString(),
            addedBy: userFullName,
            initiatedBy: "Mk3 Staff",
            noteTypeId: 4,
            subTypeId: 6,
            reasonId: 3,
            //notes: userFullName + ' changed claim original status from \'At Quote\' to \'Quote Submitted\' after  estimator  has  entered '+ this.datePipe.transform(this.form.controls.quoteSubmittedDate.value, 'yyyy-MM-dd')?.toString()!,
            notes: userFullName + ' has confirmed that the Quote has been completed on ENData on '+ this.datePipe.transform(this.form.controls.quoteSubmittedDate.value, 'yyyy-MM-dd')?.toString()! +' for '+(this.form.controls.bldEstimate.value? 'Building Estimate: $'+this.form.controls.bldEstimate.value:'')+(this.form.controls.restorationEstimate.value? (this.form.controls.bldEstimate.value? ' and Restoration Estimate: $'+this.form.controls.restorationEstimate.value : 'Restoration Estimate: $'+this.form.controls.restorationEstimate.value):''),
            private: 0,//-1 = Private, 0 = Public
            timeStamp: null,
            followUpDate: null,
            followUpUser: null,
            followUpComplete: null,
            clientId: null,
            isExternalAssessorVisible:false,
          };
          
          let successMessageShown = false;
          let errorMessageShown = false;
          this.restService.updateClaimDetails(this.refNo, body)
            .pipe(
              first(),
              concatMap(() => this.restService.addNewNote(this.newNote).pipe(first())),
              concatMap(() => from(this.generateActionsList()).pipe(
                concatMap((actionLogs) => from(actionLogs).pipe(
                  concatMap((actionLog) => this.restService.addUserActionsLogDatum(actionLog))
                ))
              )),
              catchError((error) => {
                if (!errorMessageShown) {
                  if (error.error instanceof ErrorEvent) {
                    this.errorMsg = `Error: ${error.error.message}`;
                  } else {
                    this.errorMsg = error;
                  }
                  this.alertService.clear();
                  this.alertService.error(this.errorMsg);
                  Swal.fire('Failed to add new note!', '', 'error');
                  errorMessageShown = true;
                }
                return throwError(this.errorMsg);
              })
            )
            .subscribe({
              next: () => {
                if (!successMessageShown) {
                  Swal.fire('New note has been added successfully!', '', 'success');
                  successMessageShown = true;
                  this.router.navigate(['../case-details/' + this.claim.refNo]).then(() => {});
                }
              }
            });
          }
        }
      });
    }
}

  public generateActionsList=async ():Promise<UserActionsLogDatum[]>=>{
    let actions: UserActionsLogDatum[]= [];
    const actionLog =  new UserActionsLogDatum({refNo:this.refNo,employeeID:this.employeeId});
    if ((this.claim.bldEstimate !== null && this.form.controls.bldEstimate.value===null) || (this.claim.bldEstimate===null && this.form.controls.bldEstimate.value!==null) || (this.claim.bldEstimate.toFixed(2) != this.form.controls.bldEstimate.value.toString())){
      let logBldEstimate =  new UserActionsLogDatum({refNo:this.refNo,employeeID:this.employeeId});
      logBldEstimate.fieldName = 'Building Estimate';
      logBldEstimate.oldValue= this.claim.bldEstimate ? this.claim.bldEstimate.toString() : null;
      logBldEstimate.newValue= this.form.controls.bldEstimate.value;
      actions.push(logBldEstimate);
    }

    if ((this.claim.restorationEstimate !== null && this.form.controls.restorationEstimate.value===null) || (this.claim.restorationEstimate===null && this.form.controls.restorationEstimate.value!==null) || (this.claim.restorationEstimate.toFixed(2) != this.form.controls.restorationEstimate.value.toString())){
      let logRestorationEstimate =  new UserActionsLogDatum({refNo:this.refNo,employeeID:this.employeeId});
      logRestorationEstimate.fieldName = 'Restoration Estimate';
      logRestorationEstimate.oldValue= this.claim.restorationEstimate ? this.claim.restorationEstimate.toString() : null;
      logRestorationEstimate.newValue= this.form.controls.restorationEstimate.value;
      actions.push(logRestorationEstimate);
    }

    const day = new Date(this.form.controls['quoteSubmittedDate'].value);
    const oldDay= this.claim.quoteSubmittedDate? new Date(this.claim.quoteSubmittedDate) : null;
    if (!oldDay || !(oldDay.getFullYear() === day.getFullYear() && oldDay.getMonth() === day.getMonth() && oldDay.getDate() === day.getDate())){
      let logQuoteSubmittedDate =  new UserActionsLogDatum({refNo:this.refNo,employeeID:this.employeeId});
      logQuoteSubmittedDate.fieldName = 'Quote Submitted Date';
      logQuoteSubmittedDate.oldValue= this.claim.quoteSubmittedDate ? this.claim.quoteSubmittedDate.toLocaleDateString() : null;
      logQuoteSubmittedDate.newValue= this.datePipe.transform(this.form.controls.quoteSubmittedDate.value, 'yyyy-MM-dd')?.toString()!;//this.form.controls.quoteSubmittedDate.value;
      actions.push(logQuoteSubmittedDate);
    }

    return actions;
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.form.controls[controlName].hasError(errorName);
  }
}